import axios from "axios";
import { addToNotifications } from "./notificationActions";
import { translate } from "utils/i18n";

export const checkInAttendance = (userId) => async () => {
    try {
        await axios.post("/api/v1/attendance/checkin", { userId });
    } catch (err) {
        console.log(err);
    }
};

export const checkOutAttendance = (userId) => async (dispatch) => {
    if (!userId) return;
    try {
        await axios.post("/api/v1/attendance/checkout", { userId });
        dispatch(
            addToNotifications({
                type: "SUCCESS",
                message: translate("Checked out successfully!"),
            })
        );
    } catch (err) {
        console.log(err);
        dispatch(
            addToNotifications({
                type: "ERROR",
                message: translate("Something went wrong!"),
            })
        );
    }
};

export const getAttendances = async (queryString) => {
    const { data } = await axios.get(`/api/v1/attendance?${queryString}`);
    return data;
}

export const exportAttendances = async (queryString) => {
    return await axios.get(`/api/v1/attendance/excel?${queryString}`, { responseType: "arraybuffer" });
};
