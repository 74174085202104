import React, { useEffect, useState } from "react";
import { translate } from "utils/i18n";
import { Button, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CustomDateTimePicker from "components/common/CustomDateTimePicker";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AttendanceTable from "./AttendanceTable";
import {
    downloadFileWithDepositionAttachment,
} from "store/actions/checkInCheckOutActions";
import { exportAttendances, getAttendances } from "store/actions/attendanceAction";
import moment from "moment-timezone";

const INITIAL_SETTINGS = {
    limit: 18,
    limitFieldFocused: false,
    timeMin: moment().startOf("day"),
    timeMax: moment().endOf("day"),
};

const Attendance = () => {
    const [attendances, setAttendances] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [settingsForm, setSettingsForm] = useState(INITIAL_SETTINGS);

    const setSettingsFormDateValue = (field, date) => {
        setSettingsForm({
            ...settingsForm,
            [field]: date,
        });
    };

    const handlePageChange = (_, current) => {
        setPage(current);
    }

    const fetchAttendances = async (
        page,
        timeMin = settingsForm.timeMin,
        timeMax = settingsForm.timeMax
    ) => {
        setIsLoading(true);
        const _timeMin = new Date(timeMin.toISOString());
        const _timeMax = new Date(timeMax.toISOString());
        const queryString = `gte=${_timeMin}&lte=${_timeMax}&page=${page}&limit=${settingsForm.limit}`;
        try {
            const data = await getAttendances(queryString);
            if (data.success) {
                setAttendances(data.data.docs);
                setTotalPage(data.data.totalPages);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const onExportToExcel = async () => {
        setIsDownloading(true);
        const _timeMin = new Date(settingsForm.timeMin.toISOString());
        const _timeMax = new Date(settingsForm.timeMax.toISOString());
        const fileName = `attendances-${Date.now()}.xlsx`;
        const query = `gte=${_timeMin}&lte=${_timeMax}&fileName=${fileName}`;
        const res = await exportAttendances(query);
        console.log(res);
        if (res) downloadFileWithDepositionAttachment(res);
        setIsDownloading(false);
    };

    useEffect(() => {
        fetchAttendances(page);
    }, [page]);

    return (
        <Grid container justifyContent="space-between" alignItems={"center"}>
            <Grid item>
                <div style={{ padding: "2em" }}>
                    <CustomDateTimePicker
                        value={settingsForm.timeMin}
                        onAccept={(date) =>
                            fetchAttendances(page, date, settingsForm.timeMax)
                        }
                        onChangeDate={(date) =>
                            setSettingsFormDateValue("timeMin", date)
                        }
                        disabled={isLoading}
                        inputDisabled={isLoading}
                    />
                    <ArrowForwardIcon style={{ margin: '0 1em'}}/>
                    <CustomDateTimePicker
                        value={settingsForm.timeMax}
                        onAccept={(date) =>
                            fetchAttendances(page, settingsForm.timeMin, date)
                        }
                        onChangeDate={(date) =>
                            setSettingsFormDateValue("timeMax", date)
                        }
                        disabled={isLoading}
                        inputDisabled={isLoading}
                    />
                </div>
            </Grid>
            <Grid item>
                <Button
                    onClick={onExportToExcel}
                    size="large"
                    variant={"outlined"}
                    disabled={isDownloading}
                    style={{ marginRight: 12 }}
                >
                    <FileCopyIcon />
                    {translate("Export all to excel")}
                </Button>
            </Grid>

            <Grid item xs={12}>
                <div style={{ height: "80vh", overflow: "auto" }}>
                    <AttendanceTable attendances={attendances} />
                </div>
            </Grid>

            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Pagination
                        count={totalPage}
                        page={page}
                        onChange={handlePageChange}
                        disabled={isLoading}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Attendance;
