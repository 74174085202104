import React, { useCallback, useEffect, useMemo, useState } from "react";
import useStyles from "../../styles/pages/AdminPageStyles";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from '@material-ui/icons/Group';
import EventNoteIcon from "@material-ui/icons/EventNote";
import User from "../UI/template/admin/user/User";
import Meetings from "../UI/template/admin/meeting/Meeting";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useAppearanceSettings } from "../../hooks/settings/useAppearanceSettings";
import aimesoftLogo from "../../images/aimesoft_logo.png";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import { translate } from "../../utils/i18n";
import { useSelector } from "react-redux";
import { localeMap } from "../../styles/constants";
import Tracker from "../UI/template/admin/tracker/Tracker";
import Attendance from "components/UI/template/admin/attendace/Attendance";

const AdminPage = ({ history, location }) => {
    const classes = useStyles();
    const { logo } = useAppearanceSettings(["logo"]);
    const language = useSelector((state) => state.language.currentLanguage);
    const [currentUser, setCurrentUser] = useState(null);
    const [searchText, setSearchText] = useState("");

    const [selectedIndex, setSelectedIndex] = useState(2);

    const options = useMemo(() => ["Meeting", "Users", "Attendance"], []);

    const optionsIcon = useMemo(
        () => [
            <EventNoteIcon className={classes.item} />,
            <PersonIcon className={classes.item} />,
            <GroupIcon className={classes.item} />,
        ],
        [classes.item]
    );

    const handleSetSelectedIndexForTracker = useCallback((index, user) => {
        setSelectedIndex(index);
        setCurrentUser(user);
    }, []);

    const handleMenuItemClick = (event, index) => setSelectedIndex(index);
    const renderMenuItemComponent = useCallback(() => {
        switch (selectedIndex) {
            case 0:
                return <Meetings />;
            case 1:
                return (
                    <User
                        handleSetSelectedIndexForTracker={
                            handleSetSelectedIndexForTracker
                        }
                        inputText={searchText}
                        setInputText={setSearchText}
                    />
                );
            case 2: 
                return <Attendance />;
            case 3:
                return (
                    <Tracker
                        currentUser={currentUser}
                        setSelectedIndex={setSelectedIndex}
                    />
                );
            default:
                return (
                    <User
                        handleSetSelectedIndexForTracker={
                            handleSetSelectedIndexForTracker
                        }
                        inputText={searchText}
                        setInputText={setSearchText}
                    />
                );
        }
    }, [
        selectedIndex,
        handleSetSelectedIndexForTracker,
        currentUser,
        searchText,
    ]);

    useEffect(() => {
        if (!location.state?.routeValidated) history.push("/");
    }, []);
    return (
        <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={localeMap[language]}
        >
            <div style={{ width: "100%", height: "100%" }}>
                <Grid container style={{ height: "100%" }}>
                    <Grid
                        item
                        xs={2}
                        style={{ margin: 0, background: "rgba(0, 0, 0, 0.1)" }}
                    >
                        <div className={classes.logoContainer}>
                            <Link
                                to={"/"}
                                className={classes.removeDefaultLink}
                            >
                                <img
                                    src={logo || aimesoftLogo}
                                    alt={"aimesoft logo"}
                                    className={classes.logo}
                                />
                            </Link>
                        </div>
                        <List
                            component="nav"
                            aria-label="main mailbox folders"
                            style={{ margin: 0 }}
                        >
                            {options.map((option, index) => (
                                <ListItem
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        paddingTop: "1em",
                                        paddingBottom: "1em",
                                        alignItems: "center",
                                    }}
                                    key={option}
                                    button
                                    selected={index === selectedIndex}
                                    onClick={(event) =>
                                        handleMenuItemClick(event, index)
                                    }
                                >
                                    <ListItemIcon
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                    >
                                        {optionsIcon[index]}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={translate(option)}
                                        primaryTypographyProps={{
                                            variant: "inherit",
                                        }}
                                        className={classnames(
                                            classes.item,
                                            classes.itemText
                                        )}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={10} className={classes.gridItem}>
                        <Grid container>{renderMenuItemComponent()}</Grid>
                    </Grid>
                </Grid>
            </div>
        </MuiPickersUtilsProvider>
    );
};

AdminPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default AdminPage;
