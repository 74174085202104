import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { StyledTableCell } from "../user/UserTable";
import { translate } from "utils/i18n";
import moment from "moment";

const AttendanceTable = ({ attendances }) => {
    return (
        <TableContainer id={"trackerContent"}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <Checkbox
                                inputProps={{
                                    "aria-label": "select all attendances",
                                }}
                            />
                        </StyledTableCell>
                        <StyledTableCell>{translate("Date")}</StyledTableCell>
                        <StyledTableCell>{translate("User")}</StyledTableCell>
                        <StyledTableCell align="right">
                            {translate("First Check In")}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            {translate("Last Check Out")}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            {translate("Total Work Hours")}
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <AttendanceTableBody attendances={attendances} />
            </Table>
        </TableContainer>
    );
};

const AttendanceTableBody = ({ attendances }) => {
    if (!attendances.length) {
        return (
            <TableBody>
                <TableRow>
                    <TableCell colSpan={6} align="center">
                        {translate("No data")}
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }

    return (
        <TableBody>
            {attendances.map((attendance) => {
                const firstCheckIn = attendance.checkIn
                    ? moment(attendance.checkIn).format("LT")
                    : translate(`No Check In`);
                const lastCheckOut = attendance.checkOut
                    ? moment(attendance.checkOut).format("LT")
                    : translate(`No Check out`);
                let totalWorkHours = translate("No total work hours");

                if (attendance.checkIn && attendance.checkOut) {
                    totalWorkHours = moment(attendance.checkOut)
                        .diff(moment(attendance.checkIn), "hours", true)
                        .toFixed(2);
                }

                return (
                    <TableRow key={attendance._id}>
                        <TableCell component="th" scope="row">
                            <Checkbox />
                        </TableCell>
                        <TableCell>
                            {moment(attendance.date).format("LL")}
                        </TableCell>
                        <TableCell>{attendance?.user?.displayName}</TableCell>
                        <TableCell align="right">{firstCheckIn}</TableCell>
                        <TableCell align="right">{lastCheckOut}</TableCell>
                        <TableCell align="right">{totalWorkHours}</TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

export default AttendanceTable;
