import { DateTimePicker } from "@material-ui/pickers";
import { translate } from "../../utils/i18n";
import { IconButton, InputAdornment } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";

function CustomDateTimePicker({
    ampm = false,
    disablePast = false,
    value,
    okLabel = "Ok",
    todayLabel = "Today",
    cancelLabel = "Cancel",
    disabled,
    inputDisabled,
    isShowInputProps = true,
    onChangeDate,
    onAccept,
    ...props
}) {
    return (
        <DateTimePicker
            {...props}
            autoOk
            showTodayButton
            ampm={ampm}
            disablePast={disablePast}
            cancelLabel={translate(cancelLabel)}
            todayLabel={translate(todayLabel)}
            okLabel={translate(okLabel)}
            value={value}
            disabled={disabled}
            InputProps={
                isShowInputProps && {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={inputDisabled}>
                                <EventIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }
            }
            format="yyyy/MM/dd HH:mm"
            allowKeyboardControl={false}
            onChange={onChangeDate}
            onAccept={onAccept}
            {...props}
        />
    );
}

export default CustomDateTimePicker;
